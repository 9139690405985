import React from "react";
import { Link } from "react-router-dom";

// Styling
import styles from "./Dashboard.module.css";

const Module = props => (
  <Link className={styles.module} to={props.link} active={props.active}>
    <img className={styles.image} src={props.image} alt="" />
    <span className={styles.text}>{props.text}</span>
    <span className={styles.unit}>{props.unit}</span>
  </Link>
);

export default Module;
