import React, { Component } from "react";
import { Route, Link } from "react-router-dom";

// Settings
import paths from "../../settings/paths";
import { adalApiFetch } from "../../adalConfig";

// Components
import Layout from "./../components/Layout";
import BeveragesList from "../components/BeveragesList/BeveragesList";
import Spinner from "./../components/Spinner/Spinner";
import Error from "./../components/Error/Error";
import Divider from "../components/Divider/Divider";
import FadeIn from "../components/FadeIn/FadeIn";
import Heading from "../components/Heading/Heading";
import Button from "../components/Button/Button";

// Images
// import wineTasting from "./../../images/wine-tasting-1.jpg";

class Tasting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      tasting: {},
      feedback: []
    };
  }

  getTasting = () => {
    return adalApiFetch(
      fetch,
      `${process.env.REACT_APP_API_URL}/api/event/slug/${
        this.props.match.params.tastingSlug
      }`
    ).then(res => res.json());
  };

  getFeedback = () => {
    return adalApiFetch(
      fetch,
      `${process.env.REACT_APP_API_URL}/api/user/userfeedback`
    ).then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return [];
      }
    });
  };

  getAll = () => {
    return Promise.all([this.getTasting(), this.getFeedback()]);
  };

  getAllAverageRatings = beverageSlugs => {
    // console.log("beverages: ", beverageSlugs);

    // console.log(
    //   "getAverageRating(): ",
    //   this.getAverageRating("prosecco-di-valdobbiadene-mas-de-fer-docg")
    // );

    let averageRatingRequests = [];

    for (let beverageSlug of beverageSlugs) {
      averageRatingRequests.push(this.getAverageRating(beverageSlug));
    }

    return Promise.all(averageRatingRequests);
  };

  getAverageRating = beverageSlug => {
    return adalApiFetch(
      fetch,
      `${process.env.REACT_APP_API_URL}/api/event/slug/${
        this.props.match.params.tastingSlug
      }/${beverageSlug}/averageRating`
    ).then(res => res.json());
  };

  spliceTastingAndFeedback = (tasting, feedbackList) => {
    tasting.beverages.map((beverage, i) => {
      const feedback = feedbackList.find(feedback => {
        return feedback.beverage.id === beverage.id;
      });
      tasting.beverages[i].feedback = {};
      feedback && (tasting.beverages[i].feedback.rating = feedback.rating);
      return false;
    });

    return tasting;
  };

  componentDidMount() {
    this.getAll().then(
      ([tasting, feedback]) => {
        this.spliceTastingAndFeedback(tasting, feedback);

        this.setState({
          isLoaded: true,
          tasting: tasting,
          feedback: feedback
        });

        // Get average ratings
        const beverageSlugs = tasting.beverages.map(item => item.slug);
        this.getAllAverageRatings(beverageSlugs).then(
          averageRatings => {
            // console.log("Average ratings was successful", averageRatings);

            const beverages =
              this.state.tasting && this.state.tasting.beverages;
            // console.log("beverages: ", beverages);
            // console.log("averageRatings: ", averageRatings);

            let beveragesWithAverageRatings =
              beverages &&
              (averageRatings && averageRatings.length) &&
              beverages.map((item, i) => {
                const averageRating = averageRatings[i].averageRating;
                item.averageRating = averageRating;
                return item;
              });

            this.setState(prevState => ({
              tasting: {
                ...prevState.tasting,
                beverages: beveragesWithAverageRatings
              }
            }));
          },

          error => {
            // console.log("Could not get average ratings:", error);
          }
        );
        // Get average ratings - END
      },

      error => {
        // console.log("AJAX error:", error);
        this.setState({
          isLoaded: true,
          error
        });
      }
    );
  }

  render() {
    const tasting = this.state.tasting;
    const { error, isLoaded } = this.state;

    return (
      <Layout history={this.props.history}>
        <>
          {error ? (
            <Error
              errorMessage={error.message}
              errorMessageFun="A tasting? Today? Hmm... Are you sure?"
            />
          ) : !isLoaded ? (
            <Spinner loadingMessage="Getting bottles from the basement..." />
          ) : (
            <FadeIn>
              <Heading
                intro={tasting.host}
                heading={tasting.title}
                description={tasting.abstract}
              >
                {/* Show link to host presentation and score board if presenting */}
                <Route
                  path="/presentation"
                  render={() => (
                    <>
                      <Link to={this.props.match.url + paths.host}>
                        <Button
                          text="Host"
                          type="button"
                          priority="secondary"
                        />
                      </Link>
                      <Link to={this.props.match.url + paths.scoreBoard}>
                        <Button
                          text="Score Board"
                          type="button"
                          priority="secondary"
                        />
                      </Link>
                    </>
                  )}
                />
              </Heading>

              <Divider
                image={tasting.eventType.typeIconUrl}
                alt={tasting.eventType.title}
                spacing="spacious"
                narrow="true"
              />

              <BeveragesList
                beverages={tasting.beverages}
                tastingSlug={this.props.match.params.tastingSlug}
                match={this.props.match}
              />
            </FadeIn>
          )}
        </>
      </Layout>
    );
  }
}

export default Tasting;
