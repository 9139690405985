import React from "react";

// Components
//import Component from './react/components/Component';

// Styles
import styles from "./Section.module.css"; // Import css modules stylesheet as styles

const Section = props => <div className={styles.section}>{props.children}</div>;

export default Section;
