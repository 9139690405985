import React, { Component } from "react";

// Settings
// import paths from "../../../settings/paths";

// Helpers
import { goUp } from "../../../helpers/url";

// Components
import Layout from "./../../components/Layout";
import StepList from "../../components/StepList/StepList";

// Images
import Close from "../../../images/icons/close--white.svg";

class Onboarding extends Component {
  steps = [
    {
      position: 1,
      text: "Go to vino.valtech.dk"
    },
    {
      position: 2,
      text: "Sign in with your Valtech account"
    }
  ];

  render() {
    return (
      <Layout navigation={false} fullHeight="true">
        <div className="onboarding">
          <div className="close" onClick={() => goUp(this.props.history)}>
            <img className="close__icon" src={Close} alt="Close onboarding" />
          </div>
          <StepList steps={this.steps} />
        </div>
      </Layout>
    );
  }
}

export default Onboarding;
