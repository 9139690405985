import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

// Components
import Button from "../Button/Button";
import SectionContainer from "../Section/SectionContainer";
import Section from "../Section/Section";
import Paragraph from "../Generic/Paragraph";

// Styling
import styles from "./Landing.module.css"; // Import css modules stylesheet as styles

// Images
import logo from "../../../images/logo.svg";
import vine from "../../../images/mood/vine.png";
import vine2 from "../../../images/mood/vine-2.png";

const Landing = props => (
  <div className={styles.landing}>
    <TransitionGroup>
      <CSSTransition
        component="div"
        classNames={{
          appear: styles.vineBottomRightAppear,
          appearActive: styles.vineBottomRightAppearActive,
          enter: styles.vineBottomRightEnter,
          enterActive: styles.vineBottomRightEnterActive,
          enterDone: styles.vineBottomRightEnterDone,
          exit: styles.vineBottomRightExit,
          exitActive: styles.vineBottomRightExitActive,
          exitDone: styles.vineBottomRightExitDone
        }}
        appear={true}
        timeout={250}
        enter={false}
        exit={false}
      >
        <img className={styles.vineBottomRight} src={vine} alt="Vine" />
      </CSSTransition>
    </TransitionGroup>
    <TransitionGroup>
      <CSSTransition
        component="div"
        classNames={{
          appear: styles.vineTopLeftAppear,
          appearActive: styles.vineTopLeftAppearActive,
          enter: styles.vineTopLeftEnter,
          enterActive: styles.vineTopLeftEnterActive,
          enterDone: styles.vineTopLeftEnterDone,
          exit: styles.vineTopLeftExit,
          exitActive: styles.vineTopLeftExitActive,
          exitDone: styles.vineTopLeftExitDone
        }}
        appear={true}
        timeout={250}
        enter={false}
        exit={false}
      >
        <img className={styles.vineTopLeft} src={vine2} alt="Vine" />
      </CSSTransition>
    </TransitionGroup>

    <div className={styles.backgroundColor}>
      <SectionContainer>
        <Section>
          <img className={styles.logo} src={logo} alt="Logo" />
        </Section>

        <Section>
          <Paragraph>Proceed below!</Paragraph>
        </Section>

        <Section>
          <Button
            type="button"
            text={"Let's go!"}
            appearance="big"
            onClick={() => {
              props.history.push("/tastings");
            }}
          />
        </Section>
      </SectionContainer>
    </div>
  </div>
);

export default Landing;
