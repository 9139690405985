import React from "react";

// Helpers
import { formatPrice } from "./../../../helpers/money";

// Components
//import Component from './react/components/Component';

// Styling
import styles from "./NumberedList.module.css";

const NumberedListItem = props => (
  <li className={styles.item}>
    <span className={styles.count}>{props.count + 1}. </span>
    <div className={styles.column}>
      <div className={styles.row}>
        <span className={styles.name}>{props.feedback.userFullName}</span>
      </div>
      {props.accumulated && (
        <div className={styles.row}>
          <span className={styles.guesses}>
            Number of guesses: {props.feedback.priceGuessOffsets.length} of{" "}
            {props.numberOfBeverages}
          </span>
        </div>
      )}
    </div>
    <span className={styles.difference}>
      {!props.accumulated && props.feedback.priceGuessOffset > 0 && "+"}
      {formatPrice(
        !props.accumulated
          ? props.feedback.priceGuessOffset
          : props.feedback.priceGuessOffsetAverage
      )}
    </span>
  </li>
);

const NumberedList = props => (
  <ol className={styles.list}>
    {props.list.map((feedback, i) => (
      <NumberedListItem
        key={i}
        feedback={feedback}
        count={i}
        accumulated={props.accumulated}
        numberOfBeverages={props.numberOfBeverages}
      />
    ))}
  </ol>
);

export default NumberedList;
