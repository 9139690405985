import React from "react";

// Components
import BeverageFactList from "../BeverageFactList/BeverageFactList";
import BeverageFactItem from "../BeverageFactList/BeverageFactItem";

// Images
// Facts
import calendar from "./../../../images/icons/facts/calendar.png";
import country from "./../../../images/icons/facts/country.png";
import grape from "./../../../images/icons/facts/grape.png";
import vineyard from "./../../../images/icons/facts/vineyard.png";

const Presentation = props => {
  // console.log("Presentation props: ", props);
  const beverage = props.beverage;
  return (
    <div className="presentation">
      <div className="presentation__bottle">
        <img
          className="presentation__image"
          src={beverage.imageUrl + "?h=500"}
          alt="Bottle"
        />
      </div>
      <div className="presentation__facts">
        <BeverageFactList>
          <BeverageFactItem term="Year" icon={calendar} appearance="big">
            {beverage.year}
          </BeverageFactItem>
          <BeverageFactItem term="Grapes" icon={grape} appearance="big">
            {beverage.grapes}
          </BeverageFactItem>
          <BeverageFactItem term="Region" icon={country} appearance="big">
            {!beverage.region && !beverage.country ? (
              ""
            ) : (
              <>
                {beverage.region && (
                  <span className="comma-separated-list">
                    {beverage.region}
                  </span>
                )}
                {beverage.country && (
                  <span className="comma-separated-list">
                    {beverage.country}
                  </span>
                )}
              </>
            )}
          </BeverageFactItem>
          <BeverageFactItem term="Producer" icon={vineyard} appearance="big">
            {beverage.producer}
          </BeverageFactItem>
        </BeverageFactList>
      </div>
    </div>
  );
};

export default Presentation;
