import React, { Component } from "react";

// Settings
import { adalApiFetch } from "../../../adalConfig";

// Helpers
import { goUp } from "../../../helpers/url";
import { sortClosestFirst } from "../../../helpers/sorting";

// Components
import Layout from "../../components/Layout";
import Spinner from "../../components/Spinner/Spinner";
import Error from "../../components/Error/Error";
import NumberedList from "../../components/NumberedList/NumberedList";
import Heading from "../../components/Heading/Heading";
import Divider from "../../components/Divider/Divider";

// Images
import Close from "../../../images/icons/close--blue.svg";
import Medal from "../../../images/icons/medal.png";

class ScoreBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      tasting: {},
      feedbackAccumulated: {}
    };
  }

  getTasting = () => {
    return adalApiFetch(
      fetch,
      `${process.env.REACT_APP_API_URL}/api/event/slug/${
        this.props.match.params.tastingSlug
      }`
    ).then(res => res.json());
  };

  getTastingSummary = tastingSlug => {
    return adalApiFetch(
      fetch,
      `${process.env.REACT_APP_API_URL}/api/presentation/summary/${tastingSlug}`
    ).then(res => res.json());
  };

  // getAllFeedback = beverageSlugs => {
  //   let FeedbackList = [];

  //   for (let beverageSlug of beverageSlugs) {
  //     FeedbackList.push(this.getFeedback(beverageSlug));
  //   }

  //   return Promise.all(FeedbackList);
  // };

  // // Feedback (Taken from Presentation/Beverage.js)
  // getFeedback = beverageSlug => {
  //   return adalApiFetch(
  //     fetch,
  //     `${process.env.REACT_APP_API_URL}/api/Beverage/${beverageSlug}/feedbacksummary`
  //   )
  //     .then(response => {
  //       if (!response.ok) {
  //         // console.warn(
  //         //   "Fetch error:",
  //         //   response.status,
  //         //   response.statusText,
  //         //   response
  //         // );
  //         this.setState({
  //           isLoaded: true
  //           // error: { message: response.statusText }
  //         });
  //         throw Error(response.statusText);
  //       }
  //       return response;
  //     })
  //     .then(res => res.json());
  // };

  componentDidMount() {
    this.getTasting().then(
      tasting => {
        this.setState({
          tasting: tasting
        });

        // Get all feedback
        const tastingSlug = this.props.match.params.tastingSlug;
        this.getTastingSummary(tastingSlug).then(
          tastingFeedback => {
            // console.log("All feedback was successful", tastingFeedback);
            let people = {};

            for (let beverageFeedback of tastingFeedback) {
              const price = beverageFeedback.beverage.pricePrBottle;

              for (let personFeedback of beverageFeedback.feedback) {
                const userId = personFeedback.userIdHash;
                const priceGuess = personFeedback.priceGuess;
                const priceGuessOffset = Math.abs(priceGuess - price);

                // !people[userId] && (people[userId] = []);
                !people[userId] && (people[userId] = personFeedback);

                !people[userId].priceGuessOffsets &&
                  (people[userId].priceGuessOffsets = []);

                // people[userId].push(priceGuessOffset);
                people[userId].priceGuessOffsets.push(priceGuessOffset);
              }
            }

            // Make array out of object
            let feedbackList = [];
            Object.keys(people).forEach(function(key) {
              const person = people[key];

              // Average of priceGuessOffsets
              let averageGuess =
                person.priceGuessOffsets.reduce((a, b) => a + b, 0) /
                person.priceGuessOffsets.length;

              averageGuess = Math.round(averageGuess * 100) / 100;

              person.priceGuessOffsetAverage = averageGuess;

              feedbackList.push(person);
            });

            this.setState({
              feedbackAccumulated: feedbackList,
              isLoaded: true
            });
          },

          error => {
            // console.log("Could not get all feedback:", error);
          }
        );
        // Get all feedback - END
      },

      error => {
        // console.log("AJAX error:", error);
        this.setState({
          isLoaded: true,
          error
        });
      }
    );
  }

  render() {
    const { error, isLoaded } = this.state;
    const tasting = this.state.tasting;
    const feedbackList = this.state.feedbackAccumulated;
    const isPresentation =
      this.props.match.path ===
      "/presentation/tastings/:tastingSlug/score-board";

    return (
      <>
        {error ? (
          <Error
            errorMessage={error.message}
            errorMessageFun="A tasting? Today? Hmm... Are you sure?"
          />
        ) : !isLoaded ? (
          <Spinner loadingMessage="Getting bottles from the basement..." />
        ) : (
          <>
            {/* <Layout navigation={false} fullHeight="true"> */}
            <Layout navigation={false}>
              <>
                <Heading
                  intro={tasting.title}
                  heading={"Score board"}
                  description={"Average guess distance from price"}
                  presentationMode={isPresentation && "true"}
                />

                <Divider
                  image={Medal}
                  alt={tasting.eventType.title}
                  spacing="spacious"
                  narrow="true"
                  presentationMode={isPresentation && "true"}
                />

                <div className="close" onClick={() => goUp(this.props.history)}>
                  <img className="close__icon" src={Close} alt="Close host" />
                </div>
                <div className="priceGuess">
                  <div className="priceGuess__list">
                    {feedbackList && feedbackList.length ? (
                      <NumberedList
                        list={sortClosestFirst(
                          feedbackList,
                          "priceGuessOffsetAverage"
                        )}
                        numberOfBeverages={tasting.beverages.length}
                        accumulated={true}
                      />
                    ) : (
                      "No guesses has been submitted yet."
                    )}
                  </div>
                </div>
              </>
            </Layout>
          </>
        )}
      </>
    );
  }
}

export default ScoreBoard;
