import React, { Component } from "react";

// Settings
import { adalApiFetch } from "../../adalConfig";

// Components
import Layout from "./../components/Layout";
import FadeIn from "./../components/FadeIn/FadeIn";
import Avatar from "../components/Avatar/Avatar";
import FormSectionInput from "../components/Form/FormSectionInput";
import FormSectionSelect from "../components/Form/FormSectionSelect";
import Spinner from "./../components/Spinner/Spinner";
import Error from "./../components/Error/Error";
import Divider from "../components/Divider/Divider";
import Button from "../components/Button/Button";
import Paragraph from "../components/Generic/Paragraph";
import Heading from "../components/Heading/Heading";
import SectionContainer from "../components/Section/SectionContainer";
import SectionGroup from "../components/Section/SectionGroup";
import Section from "../components/Section/Section";

// Styling
// TODO: This should not import styles from Form, it should import components that has styling from Form
import styles from "../components/Form/Form.module.css"; // Import css modules stylesheet as styles

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      profile: {},
      profileHasChanged: false,
      profileIsChanging: false,
      teams: [],
      jobs: []
    };
  }

  getUser = () => {
    return adalApiFetch(fetch, `${process.env.REACT_APP_API_URL}/api/user/`).then(res =>
      res.json()
    );
  };

  getSexes = () => {
    return adalApiFetch(fetch, `${process.env.REACT_APP_API_URL}/api/user/sexes`).then(
      res => res.json()
    );
  };

  getTeams = () => {
    return adalApiFetch(fetch, `${process.env.REACT_APP_API_URL}/api/user/teams`).then(
      res => res.json()
    );
  };

  getJobs = () => {
    return adalApiFetch(fetch, `${process.env.REACT_APP_API_URL}/api/user/jobs`).then(res =>
      res.json()
    );
  };

  getAll = () => {
    return Promise.all([
      this.getUser(),
      this.getSexes(),
      this.getTeams(),
      this.getJobs()
    ]);
  };

  componentDidMount() {
    this.getAll().then(
      ([profile, sexes, teams, jobs]) => {
        this.setState({
          isLoaded: true,
          profile: profile,
          sexes: sexes,
          teams: teams,
          jobs: jobs
        });
      },
      error => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    );
  }

  handleChange = event => {
    const target = event.target;
    const name = target.name;
    let value;
    let image = {};

    if (target.type === "checkbox") {
      value = target.checked;
    } else if (target.type === "file") {
      // TODO: When sending a file, see this: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
      // TODO: And this: https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
      const imageFile = target.files[0];
      value = window.URL.createObjectURL(imageFile);

      image.type = imageFile.type;
      image.size = imageFile.size;

      var reader = new FileReader();

      reader.addEventListener(
        "load",
        function() {
          let result = reader.result;
          // result = result.replace("data:image/png;base64,", "");
          // result = result.replace("data:image/jpeg;base64,", "");
          image.encodedImage = result;
        },
        false
      );

      if (imageFile) {
        reader.readAsDataURL(imageFile);
      }
    } else {
      value = target.value;
    }

    // To only update a part of the state object, spread prevState and overwrite with the new value
    this.setState(prevState => ({
      profile: {
        ...prevState.profile,
        [name]: value,
        image: image
      },
      profileHasChanged: true
    }));
  };

  updateProfileSettings = event => {
    this.setState(prevState => ({
      profileIsChanging: true
    }));
    // console.log(this.state.profile);
    event.preventDefault();
    return adalApiFetch(fetch, `${process.env.REACT_APP_API_URL}/api/user`, {
      method: "POST",
      body: JSON.stringify(this.state.profile),
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      }
    }).then(res =>
      res.json().then(
        () => {
          this.setState({ profileIsChanging: false, profileHasChanged: false });
        },
        error => {
          // console.log("error: ", error);
          this.setState({
            error
          });
        }
      )
    );
  };

  render() {
    const profile = this.state.profile;
    const { error, isLoaded } = this.state;
    // console.log("The state.profile when rendering: ", this.state.profile);

    return (
      <Layout history={this.props.history}>
        <>
          {error ? (
            <Error
              errorMessage={error.message}
              errorMessageFun="Who are you again?"
            />
          ) : !isLoaded ? (
            <Spinner loadingMessage="We are retrieving your file..." />
          ) : (
            <FadeIn>
              <SectionContainer is-first-on-page="true">
                {/* TODO: <form> has inline styling */}
                <form
                  onSubmit={this.updateProfileSettings}
                  style={{ display: "contents" }}
                >
                  <Section>
                    <input
                      className={styles.file}
                      // type="file"// Temporarily disabled interaction
                      id="avatar"
                      name="imageUrl"
                      // accept="image/png, image/jpeg"// Temporarily disabled interaction
                      // onChange={this.handleChange}// Temporarily disabled interaction
                    />

                    <label
                      className={styles.label + " " + styles.imageLabel}
                      htmlFor="avatar"
                    >
                      <Avatar imageUrl={profile.imageUrl} />
                    </label>
                  </Section>

                  <FormSectionInput
                    name="name"
                    type="text"
                    value={profile.name}
                    labelText="Name"
                    changeHandler={this.handleChange}
                  />

                  <SectionGroup>
                    <FormSectionSelect
                      name="sex"
                      value={profile.sex}
                      labelText="Sex"
                      optionHidden="Pick one"
                      options={this.state.sexes.map(sex => ({
                        name: sex,
                        value: sex
                      }))}
                      changeHandler={this.handleChange}
                    />

                    <FormSectionInput
                      name="birthYear"
                      type="number"
                      min="1900"
                      max="2018"
                      value={profile.birthYear}
                      labelText="Birth Year"
                      changeHandler={this.handleChange}
                    />
                  </SectionGroup>

                  <FormSectionSelect
                    name="teamId"
                    value={profile.teamId}
                    labelText="Team"
                    optionHidden="Be part of a team!"
                    options={this.state.teams.map(team => ({
                      name: team.teamName,
                      value: team.id
                    }))}
                    changeHandler={this.handleChange}
                  />

                  <FormSectionSelect
                    name="jobTitle"
                    value={profile.jobTitle}
                    labelText="Job Title"
                    optionHidden="What do you actually do?"
                    options={this.state.jobs.map(job => ({
                      name: job,
                      value: job
                    }))}
                    changeHandler={this.handleChange}
                  />

                  <Section>
                    <Button
                      text="Update"
                      type="submit"
                      processing={
                        this.state.profileIsChanging ? "Updating" : null
                      }
                      inactive={
                        !this.state.profileHasChanged ? "Updated" : null
                      }
                    />
                    <Button
                      text="Sign out"
                      type="button"
                      priority="secondary"
                      onClick={() => {
                        localStorage.clear();
                        window.location =
                          "//login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=https://vino.valtech.dk";
                      }}
                    />
                  </Section>
                </form>
              </SectionContainer>

              <Divider appearance="faint" spacing="spacious" />

              <SectionContainer>
                <Section>
                  <Heading subHeading="Data" />
                  <Paragraph align={"left"}>
                    We solely use your the data we have about you for
                    statistical purposes at the wine tastings. We have a lot of
                    fun from presenting for the audience some of the connections
                    between job title and wine preferences or what ever. Your
                    data is not given to any third party and you are free to
                    delete your data when ever you want. It's the law!
                  </Paragraph>
                  <Paragraph align={"left"}>
                    We know about you what you see on this page and how you
                    liked the wines in the past, that is basically it!
                  </Paragraph>
                  <Button
                    text="Forget me"
                    type="button"
                    priority="secondary"
                    onClick={event => {
                      alert(
                        "Please approach an organizer of Vino Valtech if you want your account and your data deleted."
                      );
                    }}
                  />
                </Section>
              </SectionContainer>
            </FadeIn>
          )}
        </>
      </Layout>
    );
  }
}

export default Settings;
