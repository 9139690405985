import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";

export const adalConfig = {
  tenant: "VALTECHCOM.onmicrosoft.com",
  clientId: "47381d08-7455-4c4c-87a2-0424b9c888b6",
  endpoints: {
    api:
      "https://VALTECHCOM.onmicrosoft.com/3d559d00-53e5-4972-9e90-a7a982d650d1"
  },
  // postLogoutRedirectUri: "https://localhost:3000",
  redirectUri: window.location.origin,
  navigateToLoginRequestUrl: true,
  cacheLocation: "localStorage"
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  // adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
  adalFetch(authContext, adalConfig.clientId, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(
  authContext,
  adalConfig.endpoints.api
);