import React from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./Marquee.module.css";

const Marquee = props => (
  <div className={styles.marquee} active={props.active.toString()}>
    <div className={styles.content}>{props.children}</div>
  </div>
);

Marquee.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.object
};

export default Marquee;
