import React from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./Quiz.module.css";

// Components
import FormSectionInput from "../Form/FormSectionInput";
import SectionContainer from "../Section/SectionContainer";
import SectionGroup from "../Section/SectionGroup";
import Section from "../Section/Section";
import Button from "../Button/Button";

const Quiz = props => (
  <>
    <div className={styles.quiz}>
      <p className={styles.quiz__question}>What is the price of this bottle?</p>
      <SectionContainer is-first-on-page="true">
        <form onSubmit={props.submitHandler} style={{ display: "contents" }}>
          <SectionGroup>
            <FormSectionInput
              name="priceGuess"
              type="number"
              min="0"
              value={props.priceGuess ? props.priceGuess : ""}
              labelText="DKK"
              changeHandler={props.changeHandler}
              disabled={props.isLocked && "disabled"}
            />

            <Section>
              <Button
                type="submit"
                text="Submit"
                processing={
                  props.isProcessing
                    ? "Submitting"
                    : props.isWaiting
                    ? "Waiting"
                    : null
                }
                inactive={props.isLocked ? "Submitted" : null}
              />
            </Section>
          </SectionGroup>
        </form>
      </SectionContainer>
    </div>
  </>
);

Quiz.propTypes = {
  submitHandler: PropTypes.func,
  priceGuess: PropTypes.number,
  changeHandler: PropTypes.func,
  isLocked: PropTypes.bool,
  isProcessing: PropTypes.bool
};

export default Quiz;
