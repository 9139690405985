import React, { Component } from "react";

// Settings
import { adalApiFetch } from "../../../adalConfig";

// Helpers
import { goUp } from "../../../helpers/url";

// Components
import Layout from "../../components/Layout";
import Spinner from "../../components/Spinner/Spinner";
import Error from "../../components/Error/Error";

// Images
import Close from "../../../images/icons/close--blue.svg";

class Host extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      tasting: {}
    };
  }

  getTasting = () => {
    return adalApiFetch(
      fetch,
      `${process.env.REACT_APP_API_URL}/api/event/slug/${
        this.props.match.params.tastingSlug
      }`
    ).then(res => res.json());
  };

  componentDidMount() {
    this.getTasting().then(
      tasting => {
        this.setState({
          isLoaded: true,
          tasting: tasting
        });
      },

      error => {
        // console.log("AJAX error:", error);
        this.setState({
          isLoaded: true,
          error
        });
      }
    );
  }

  render() {
    const tasting = this.state.tasting;
    const { error, isLoaded } = this.state;

    return (
      <>
        {error ? (
          <Error
            errorMessage={error.message}
            errorMessageFun="A tasting? Today? Hmm... Are you sure?"
          />
        ) : !isLoaded ? (
          <Spinner loadingMessage="Getting bottles from the basement..." />
        ) : (
          <>
            <Layout navigation={false} fullHeight="true">
              <>
                <div className="close" onClick={() => goUp(this.props.history)}>
                  <img className="close__icon" src={Close} alt="Close host" />
                </div>
                <div className="host">
                  <h1 className="host__title">{tasting.title}</h1>
                  <p className="host__name">{tasting.host}</p>
                  <p className="host__abstract">{tasting.abstract}</p>
                </div>
              </>
            </Layout>
          </>
        )}
      </>
    );
  }
}

export default Host;
