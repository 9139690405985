import React from "react";

// Styling
import styles from "./Section.module.css"; // Import css modules stylesheet as styles

const SectionGroup = props => (
  <div className={styles.group}>{props.children}</div>
);

export default SectionGroup;
