import React from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./SlideIn.module.css";

// Images
import calendar from "../../../images/icons/close--white.svg";

const SlideIn = props => (
  <div className={styles.slideIn} data-visible={props.isShown}>
    <div className={styles.box}>
      {/* TODO: Close could be moved to it's own module? */}
      <div className={styles.close} onClick={props.toggleHandler}>
        <img className={styles.closeIcon} src={calendar} alt="Close quiz" />
      </div>
      {props.children}
    </div>
    <div className={styles.placeholder}>
      {/* Placeholder component to force height for slideIn */}
      {props.children}
    </div>
  </div>
);

SlideIn.propTypes = {
  isShown: PropTypes.bool,
  toggleHandler: PropTypes.func,
  children: PropTypes.object
};

export default SlideIn;
