import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";

// Styling
import styles from "./Error.module.css";

const Error = props => (
  <TransitionGroup className={styles.error}>
    <CSSTransition
      component="div"
      classNames={{
        appear: styles.appear,
        appearActive: styles.appearActive,
        enter: styles.enter,
        enterActive: styles.enterActive,
        enterDone: styles.enterDone,
        exit: styles.exit,
        exitActive: styles.exitActive,
        exitDone: styles.exitDone
      }}
      appear={true}
      timeout={250}
      enter={false}
      exit={false}
    >
      <>
        <div className={styles.text}>{props.errorMessageFun}</div>
        <div className={styles.details}>Error: {props.errorMessage}</div>
      </>
    </CSSTransition>
  </TransitionGroup>
);

Error.propTypes = {
  errorMessageFun: PropTypes.string,
  errorMessage: PropTypes.string
};

export default Error;
