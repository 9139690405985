import React from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./Rating.module.css";

const RatingUnits = props => {
  let ratingUnits = [];
  let i;
  for (i = 6; i > 0; i--) {
    let rated = Math.round(props.rating) === i;
    ratingUnits.push(
      <span
        className={styles.unit}
        key={i}
        rating={i}
        rated={rated.toString()}
        onClick={
          props.simple
            ? undefined
            : rated
            ? props.changeHandler.bind(this, 0)
            : props.changeHandler.bind(this, i)
        }
      />
    );
  }
  return ratingUnits;
};

const Rating = props => (
  <div className={styles.rating} is-locked={String(props.isLocked)}>
    {!props.simple && <p className={styles.text}>What do you think?</p>}
    <div className={styles.ratings}>
      <div className={styles.units} data-simple={props.simple}>
        <RatingUnits
          rating={props.rating}
          changeHandler={!props.simple && props.changeHandler}
          simple={props.simple}
        />
      </div>
      {props.average ? (
        <p className={styles.average}>
          ({Math.round(props.average * 10) / 10})
        </p>
      ) : (
        ""
      )}
    </div>
  </div>
);

Rating.propTypes = {
  simple: PropTypes.bool,
  rating: PropTypes.number,
  changeHandler: PropTypes.func,
  average: PropTypes.number
};

export default Rating;
