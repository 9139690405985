import React from "react";
import PropTypes from "prop-types";

// Components
import Module from "./Module";

// Styling
import styles from "./Dashboard.module.css";

// Images
import glasses from "../../../images/icons/glasses.png";
import rating from "../../../images/icons/rating.png";
import piggyBank from "../../../images/icons/piggy-bank.png";

const Dashboard = props => (
  <div className={styles.dashboard}>
    <Module
      image={glasses}
      text={`${props.indexOfTasting}/${props.numberOfBeverages}`}
      unit="Wines"
      link={`${props.url}/presentation`}
      active={
        `${props.url}/presentation` === props.pathname ||
        `${props.url}` === props.pathname
          ? "true"
          : "false"
      }
    />
    <Module
      image={rating}
      // text={props.averageRating}
      text={props.numberOfRatings}
      unit="Ratings"
      link={`${props.url}/rating`}
      pathname={props.pathname}
      active={`${props.url}/rating` === props.pathname ? "true" : "false"}
    />
    <Module
      image={piggyBank}
      // text={props.averagePriceGuess}
      text={props.numberOfGuesses}
      unit="Guesses"
      link={`${props.url}/price-guess`}
      pathname={props.pathname}
      active={`${props.url}/price-guess` === props.pathname ? "true" : "false"}
    />
  </div>
);

Dashboard.propTypes = {
  indexOfTasting: PropTypes.number,
  numberOfBeverages: PropTypes.number,
  // numberOfRatings: PropTypes.number,
  numberOfRatings: PropTypes.number,
  // averagePriceGuess: PropTypes.number,
  numberOfGuesses: PropTypes.number,
  url: PropTypes.string,
  pathname: PropTypes.string
};

export default Dashboard;
