import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// Paths
import paths from "../../../settings/paths";

// Components
import BeverageBrief from "../Beverage/BeverageBrief";
import Rating from "../Rating/Rating";

// Styling
import styles from "./BeveragesItem.module.css";

// Images
import bottle from "../../../images/icons/bottles/wine--red.png";

const BeveragesItem = props => {
  const isPresentation =
    props.match.path === "/presentation/tastings/:tastingSlug";
  const linkPrefix = isPresentation ? props.match.url : "";

  return (
    <li className={styles.beveragesItem}>
      <Link
        className={styles.link}
        to={linkPrefix + paths.beverages + "/" + props.beverage.slug}
      >
        <img
          className={styles.bottle}
          src={
            props.beverage.imageUrl
              ? props.beverage.imageUrl + "?h=500"
              : bottle
          }
          alt="Bottle"
        />
        <div className={styles.info}>
          <BeverageBrief beverage={props.beverage} appearance="small" />
          <Rating
            rating={isPresentation ? props.averageRating : props.rating}
            simple={true}
            average={props.averageRating}
          />
        </div>
        <div className={styles.emptySpace} />
      </Link>
    </li>
  );
};

BeveragesItem.propTypes = {
  match: PropTypes.object
};

BeveragesItem.defaultProps = {
  match: {}
};

export default BeveragesItem;
