import React from "react";

// Components
import Avatar from "./Avatar";

// Styling
import styles from "./ProfileBrief.module.css";

const ProfileBrief = props => (
  <div className={styles.profile}>
    <div className={styles.avatar}>
      <Avatar image={props.imageUrl} />
    </div>
    {props.name && <div className={styles.name}>{props.name}</div>}
  </div>
);

export default ProfileBrief;
