import React from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./Heading.module.css";

const Heading = props => (
  <div
    className={styles.headingContainer}
    presentation-mode={props.presentationMode}
  >
    {props.image && (
      <img
        className={styles.decoration}
        src={props.image}
        alt={props.imageText}
      />
    )}
    {props.intro && <p className={styles.intro}>{props.intro}</p>}
    {props.heading && <h1 className={styles.heading}>{props.heading}</h1>}
    {props.subHeading && (
      <h2 className={styles.subHeading}>{props.subHeading}</h2>
    )}
    {props.description && (
      <p className={styles.description}>{props.description}</p>
    )}
    {props.children}
  </div>
);

Heading.propTypes = {
  image: PropTypes.string,
  imageText: PropTypes.string,
  intro: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  description: PropTypes.string
};

export default Heading;
