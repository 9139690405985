import React from "react";
import { Link } from "react-router-dom";

import paths from "../../../settings/paths";

// Helpers
import { getMonthDay } from "../../../helpers/dateFormatting";

// Styling
import styles from "./TastingsItem.module.css";

// Images
import arrowRight from "../../../images/icons/arrow-small__right--green.svg";

const TastingsItem = props => {
  const isPresentation = props.match.path === "/presentation";
  const linkPrefix = isPresentation ? "/presentation" : "";

  return (
    <li className={styles.tastingsItem}>
      <Link
        className={styles.link}
        to={linkPrefix + paths.tastings + "/" + props.slug}
      >
        <img
          className={styles.typeImage}
          src={props.eventType.typeIconUrl}
          alt={props.eventType.title}
        />
        <div className={styles.info}>
          <div className={styles.date}>
            {getMonthDay(new Date(props.eventDate), "en-us")}
          </div>
          <p className={styles.name}>{props.title}</p>
          <p className={styles.topic}>{props.abstract}</p>
        </div>
        <div className={styles.cta}>
          <img src={arrowRight} alt="Choose event" />
        </div>
      </Link>
    </li>
  );
};

export default TastingsItem;
