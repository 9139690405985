import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

// Styling
import styles from "./BeverageBottle.module.css";

// Images
// Vines
import vine from "../../../images/mood/vine.png";
import vine2 from "../../../images/mood/vine-2.png";
// Default bottle
import bottle from "../../../images/icons/bottles/wine--red.png";

const BeverageBottle = props => (
  <div
    className={styles.bottle}
    data-zoom-bottle={props.zoomBottle}
    onClick={props.handleZoomBottle}
  >
    <TransitionGroup>
      <CSSTransition
        component="div"
        classNames={{
          appear: styles.vineAppear,
          appearActive: styles.vineAppearActive,
          enter: styles.vineEnter,
          enterActive: styles.vineEnterActive,
          enterDone: styles.vineEnterDone,
          exit: styles.vineExit,
          exitActive: styles.vineExitActive,
          exitDone: styles.vineExitDone
        }}
        appear={true}
        timeout={250}
        enter={false}
        exit={false}
      >
        <div className={styles.vines}>
          <img className={styles.vine} src={vine} alt="Vine" />
          <img className={styles.vine2} src={vine2} alt="Vine" />
        </div>
      </CSSTransition>
    </TransitionGroup>

    <img
      className={styles.bottleImage}
      src={
        props.beverage.imageUrl
          ? props.beverage.imageUrl + "?h=500"
          : bottle
      }
      alt="Bottle"
    />
  </div>
);

export default BeverageBottle;
