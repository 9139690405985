import React from "react";
import PropTypes from "prop-types";

// Components
import BeveragesItem from "./BeveragesItem";

// Styling
import styles from "./BeveragesList.module.css";

const BeveragesList = props => {
  return (
    <ul className={styles.beveragesList}>
      {props.beverages.map((beverage, i) => (
        <BeveragesItem
          key={i}
          beverage={beverage}
          rating={beverage.feedback.rating}
          averageRating={beverage.averageRating}
          tastingSlug={props.tastingSlug}
          match={props.match}
        />
      ))}
    </ul>
  );
};

BeveragesList.propTypes = {
  match: PropTypes.object
};

BeveragesList.defaultProps = {
  match: {}
};

export default BeveragesList;
