import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// Settings
import paths from "./settings/paths";

// Screens
import Landing from "./react/screens/Landing";
import Tasting from "./react/screens/Tasting";
import Beverage from "./react/screens/Beverage";
import Profile from "./react/screens/Profile";
import Settings from "./react/screens/Settings";

import Onboarding from "./react/screens/Presentation/Onboarding";
import Host from "./react/screens/Presentation/Host";
import ScoreBoard from "./react/screens/Presentation/ScoreBoard";
import BeveragePresentation from "./react/screens/Presentation/Beverage";

// Components
import NotFound from "./react/screens/NotFound";

// Styling
import "./App.css";
import Tastings from "./react/screens/Tastings";

class App extends Component {
  state = {};

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path={paths.login} component={Landing} />

          <Route exact path={paths.tastings} component={Tastings} />

          <Route exact path={paths.profile} component={Profile} />
          <Route exact path={paths.profileSettings} component={Settings} />

          <Route
            exact
            path={paths.tastings + paths.tastingSlug}
            component={Tasting}
          />

          <Route
            exact
            path={paths.beverages + paths.beverageSlug}
            component={Beverage}
          />

          {/* Presentation */}
          <Route exact path="/presentation" component={Tastings} />
          <Route exact path="/presentation/onboarding" component={Onboarding} />
          <Route
            exact
            path="/presentation/tastings/:tastingSlug"
            component={Tasting}
          />
          <Route
            exact
            path="/presentation/tastings/:tastingSlug/host"
            component={Host}
          />
          <Route
            exact
            path="/presentation/tastings/:tastingSlug/score-board"
            component={ScoreBoard}
          />
          <Route
            path="/presentation/tastings/:tastingSlug/beverages/:beverageSlug"
            component={BeveragePresentation}
          />

          {/* Not Found */}
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
