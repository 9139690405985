import React, { Component } from "react";
import { Link } from "react-router-dom";

// Settings
import paths from "../../settings/paths";
import { adalApiFetch } from "../../adalConfig";

// Helpers
import { biggestFirst } from "../../helpers/sorting";

// Components
import Layout from "../components/Layout";
import FadeIn from "./../components/FadeIn/FadeIn";
import ProfileBrief from "../components/Avatar/ProfileBrief";
import Spinner from "../components/Spinner/Spinner";
import Error from "../components/Error/Error";
import Divider from "../components/Divider/Divider";
import Heading from "../components/Heading/Heading";
import BeveragesList from "../components/BeveragesList/BeveragesList";
import SectionContainer from "../components/Section/SectionContainer";
import Section from "../components/Section/Section";
import Button from "../components/Button/Button";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      profile: {},
      beverages: {}
    };
  }

  getUser = () => {
    return adalApiFetch(fetch, `${process.env.REACT_APP_API_URL}/api/user/`).then(res =>
      res.json()
    );
  };

  getFeedback = () => {
    return adalApiFetch(
      fetch,
      `${process.env.REACT_APP_API_URL}/api/user/userfeedback`
    ).then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return [];
      }
    });
  };

  getAll = () => {
    return Promise.all([this.getUser(), this.getFeedback()]);
  };

  formatFeedback = feedback => {
    const beverages = feedback.map((item, i) => {
      let result = item.beverage;
      result.feedback = {};
      result.feedback.rating = item.rating;
      return result;
    });
    return beverages;
  };

  componentDidMount() {
    this.getAll().then(
      ([profile, feedback]) => {
        const beverages = this.formatFeedback(biggestFirst(feedback, "rating"));

        this.setState({
          isLoaded: true,
          profile: profile,
          beverages: beverages
        });
      },
      error => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    );
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    // To only update a part of the state object, spread prevState and overwrite with the new value
    this.setState(prevState => ({
      profile: {
        ...prevState.profile,
        [name]: value
      }
    }));
  };

  render() {
    const profile = this.state.profile;
    const { error, isLoaded } = this.state;

    return (
      <Layout history={this.props.history}>
        <>
          {error ? (
            <Error
              errorMessage={error.message}
              errorMessageFun="Who are you again?"
            />
          ) : !isLoaded ? (
            <Spinner loadingMessage="We are retrieving your file..." />
          ) : (
            <FadeIn>
              <ProfileBrief name={profile.name} imageUrl={profile.imageUrl} />

              {/* TODO: Only show this if user is admin */}
              <Divider appearance="faint" spacing="spacious" />

              {profile.accountType === "Admin" && (
                <>
                  <SectionContainer>
                    <Section>
                      <Heading subHeading="Mode" />

                      <Link to={paths.tastings}>
                        <Button
                          text="Client"
                          type="button"
                          priority="secondary"
                        />
                      </Link>
                      <Link to={paths.presentation}>
                        <Button
                          text="Presentation"
                          type="button"
                          priority="secondary"
                        />
                      </Link>
                    </Section>
                  </SectionContainer>

                  <Divider appearance="faint" spacing="spacious" />
                </>
              )}

              <SectionContainer>
                <Section>
                  <Heading subHeading="History" />

                  <BeveragesList
                    beverages={this.state.beverages}
                    tastingSlug={this.props.match.params.tastingSlug}
                  />
                </Section>
              </SectionContainer>
            </FadeIn>
          )}
        </>
      </Layout>
    );
  }
}

export default Profile;
