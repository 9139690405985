import React from "react";

// Styling
import styles from "./Paragraph.module.css";

const Paragraph = props => (
  <p className={styles.paragraph} align={props.align}>
    {props.children}
  </p>
);

export default Paragraph;
