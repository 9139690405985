import React from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./Divider.module.css";

const Divider = props => (
  <div
    className={styles.divider}
    unobtrusive={props.unobtrusive}
    appearance={props.appearance}
    spacing={props.spacing}
    narrow={props.narrow}
    presentation-mode={props.presentationMode}
  >
    <hr className={styles.ruler} />
    {(props.image || props.text) && (
      <span className={styles.mask}>
        {props.image ? (
          <img className={styles.image} src={props.image} alt={props.alt} />
        ) : (
          <span className={styles.text}>{props.text}</span>
        )}
      </span>
    )}
  </div>
);

Divider.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
  appearance: PropTypes.string,
  unobtrusive: PropTypes.string
};

export default Divider;
