import React, { Component } from "react";

// Paths
import { adalApiFetch } from "../../adalConfig";

//Helpers
import { formatPrice } from "../../helpers/money";

// Components
import Layout from "../components/Layout";
import Spinner from "../components/Spinner/Spinner";
import Error from "../components/Error/Error";
import BeverageBottle from "../components/Beverage/BeverageBottle";
import BeverageBrief from "../components/Beverage/BeverageBrief";
import Rating from "../components/Rating/Rating";
import Quiz from "../components/Quiz/Quiz";
import FadeIn from "../components/FadeIn/FadeIn";
import SlideIn from "../components/SlideIn/SlideIn";
import Button from "../components/Button/Button";
import Paragraph from "../components/Generic/Paragraph";
import Divider from "../components/Divider/Divider";
import BeverageFactList from "../components/BeverageFactList/BeverageFactList";
import BeverageFactItem from "../components/BeverageFactList/BeverageFactItem";

// Images
// Facts
import calendar from "../../images/icons/facts/calendar.png";
import country from "../../images/icons/facts/country.png";
import grape from "../../images/icons/facts/grape.png";
import type from "../../images/icons/facts/type.png";
import vineyard from "../../images/icons/facts/vineyard.png";

// Styling
import styles from "../components/Beverage/Beverage.module.css"; // TODO: Not sure if I like having this in a higher order component

class Beverage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      beverage: {},
      eventIsGoingOn: true, // Use the real state from the service
      zoomBottle: false,
      quiz: {
        isShown: false,
        isLocked: false,
        isProcessing: false
      },
      feedback: {
        isLoaded: false,
        rating: 0,
        priceGuess: 0,
        isLocked: false
      }
    };
  }

  // Bottle image
  handleZoomBottle = () => {
    this.setState({
      zoomBottle: !this.state.zoomBottle
    });
  };

  // Rating
  handleRatingChange = rating => {
    if (this.state.feedback.isLocked) {
      // Is feedback is locked, shuld not do anything
    } else {
      this.setState(prevState => ({
        feedback: {
          ...prevState.feedback,
          rating: rating
        }
      }));

      const feedbackObj = {
        rating: rating
      };

      this.giveFeedback(feedbackObj);
    }
  };

  // Quiz
  toggleQuiz = () => {
    this.setState(prevState => ({
      quiz: {
        ...prevState.quiz,
        isShown: !this.state.quiz.isShown
      }
    }));
  };

  handleQuizChange = event => {
    const target = event.target;
    const name = target.name;
    const value = Number(target.value);

    this.setState(prevState => ({
      feedback: {
        ...prevState.feedback,
        [name]: value
      }
    }));
  };

  handleQuizSubmit = event => {
    event.preventDefault();
    this.setState(prevState => ({
      quiz: {
        ...prevState.quiz,
        isProcessing: true
      }
    }));

    const callback = () => {
      this.setState(prevState => ({
        quiz: {
          ...prevState.quiz,
          isLocked: true,
          isProcessing: false
        }
      }));

      setTimeout(() => {
        this.toggleQuiz();
      }, 1000);
    };

    this.giveFeedback({ priceGuess: this.state.feedback.priceGuess }, callback);
  };

  // Feedback
  getFeedback = () => {
    adalApiFetch(
      fetch,
      `${process.env.REACT_APP_API_URL}/api/user/userfeedback/${this.state.beverage.id}`
    )
      .then(res => res.json())
      .then(
        result => {
          // console.log("getFeedback was successful:", result);
          result.isLoaded = true;
          this.setState(prevState => ({
            feedback: result,
            quiz: {
              ...prevState.quiz,
              isLocked: result.priceGuess ? true : false
            }
          }));
        },

        error => {
          this.setState(prevState => ({
            feedback: {
              ...prevState.feedback,
              isLoaded: true
            }
          }));
          // console.log("getFeedback was unsuccessful:", error);
          // this.setState({
          //   error
          // });
        }
      );
  };

  giveFeedback = (feedbackObj, callback) => {
    // console.log("feedbackObj: ", feedbackObj);
    const rating = feedbackObj.rating;
    const priceGuess = feedbackObj.priceGuess;

    // Disable feedback buttons (hearts or quiz)
    this.setState(prevState => ({
      feedback: {
        ...prevState.feedback,
        isLocked: true
      }
    }));

    if (
      typeof this.state.feedback === "undefined" ||
      (typeof this.state.feedback !== "undefined" &&
        typeof this.state.feedback.id === "undefined")
    ) {
      // If there is no feedback on this beverage
      // console.log("Creating feedback...");

      let feedbackObject = {
        beverageId: this.state.beverage.id
      };
      if (typeof rating !== "undefined") {
        feedbackObject.rating = rating;
      }
      if (typeof priceGuess !== "undefined") {
        feedbackObject.priceGuess = priceGuess;
      }
      // console.log("feedbackObject: ", feedbackObject);

      return adalApiFetch(fetch, `${process.env.REACT_APP_API_URL}/api/userfeedback`, {
        method: "POST",
        body: JSON.stringify(feedbackObject),
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        }
      })
        .then(res =>
          res.json().then(
            result => {
              // console.log("Rating created", result);
              typeof callback === "function" && callback();

              this.setState(prevState => ({
                feedback: {
                  ...prevState.feedback,
                  id: result.id,
                  isLocked: false
                }
              }));
              // TODO: Do something?
            },
            error => {
              // TODO: Handle with a local error, don't break whole page. Maybe use the SlideIn component?
              // console.log("giveFeedback was UNsuccessful: ", error);
              this.setState(prevState => ({
                feedback: {
                  ...prevState.feedback,
                  isLocked: false
                }
              }));
              // this.setState({
              //   error
              // });
            }
          )
        )
        .catch(error => {
          // console.log("giveFeedback was UNsuccessful: ", error);
          this.setState(prevState => ({
            feedback: {
              ...prevState.feedback,
              isLocked: false
            }
          }));
        });
    } else {
      // If there is feedback on this beverage
      // console.log("Updating feedback...");

      let feedbackObject = {};
      let url = "";
      if (typeof rating !== "undefined") {
        feedbackObject.rating = rating;
        url = `${process.env.REACT_APP_API_URL}/api/userfeedback/${
          this.state.feedback.id
        }/rating`;
      }
      if (typeof priceGuess !== "undefined") {
        feedbackObject.priceGuess = priceGuess;
        url = `${process.env.REACT_APP_API_URL}/api/userfeedback/${
          this.state.feedback.id
        }/priceGuess`;
      }

      return adalApiFetch(fetch, url, {
        method: "PATCH",
        body: JSON.stringify(feedbackObject),
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        }
      })
        .then(res =>
          res.json().then(
            () => {
              // console.log("Rating updated", res);
              typeof callback === "function" && callback();

              this.setState(prevState => ({
                feedback: {
                  ...prevState.feedback,
                  isLocked: false
                }
              }));
              // TODO: Do something?
            },
            error => {
              // TODO: Handle with a local error, don't break whole page. Maybe use the SlideIn component?
              // console.log("Rating was not updated: ", error);
              this.setState(prevState => ({
                feedback: {
                  ...prevState.feedback,
                  isLocked: false
                }
              }));
              // this.setState({
              //   error
              // });
            }
          )
        )
        .catch(error => {
          // console.log("Rating was not updated: ", error);
          this.setState(prevState => ({
            feedback: {
              ...prevState.feedback,
              isLocked: false
            }
          }));
        });
    }
  };

  componentDidMount() {
    adalApiFetch(
      fetch,
      `${process.env.REACT_APP_API_URL}/api/beverage/slug/${
        this.props.match.params.beverageSlug
      }`
    )
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            beverage: result
          });

          this.getFeedback();
        },

        error => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  render() {
    const beverage = this.state.beverage;
    const feedback = this.state.feedback;
    const quiz = this.state.quiz;
    const { error, isLoaded } = this.state;

    return (
      <Layout history={this.props.history}>
        <>
          {error ? (
            <Error
              errorMessage={error.message}
              errorMessageFun="Oops! It seems we drank the last bottle. Sorry, not sorry"
            />
          ) : !isLoaded ? (
            <Spinner loadingMessage="Opening your bottle, one sec!" />
          ) : (
            <FadeIn>
              <div className={styles.beverage}>
                <BeverageBottle
                  beverage={beverage}
                  handleZoomBottle={this.handleZoomBottle}
                  zoomBottle={this.state.zoomBottle}
                />
                <div className={styles.beverage__content}>
                  <BeverageBrief beverage={beverage} />
                  <Divider appearance="faint" spacing="spacious" />
                  <Rating
                    rating={feedback.rating}
                    isLocked={feedback.isLocked}
                    changeHandler={this.handleRatingChange}
                  />
                  <Divider appearance="faint" spacing="spacious" />

                  {/* TODO: If event is going on, add PriceQuiz component */}
                  <FadeIn>
                    <Button
                      type="button"
                      text={"Guess the price"}
                      loading={
                        !this.state.feedback.isLoaded ? "Guess the price" : null
                      }
                      inactive={
                        this.state.quiz.isLocked
                          ? `Your guess: ${formatPrice(feedback.priceGuess)}`
                          : null
                      }
                      onClick={this.toggleQuiz}
                    />
                  </FadeIn>

                  {beverage.description && (
                    <Paragraph>{beverage.description}</Paragraph>
                  )}

                  <BeverageFactList>
                    {/* <BeverageFactItem term="Price" icon={type}>
                      {beverage.pricePrBottle > 0 &&
                        formatPrice(beverage.pricePrBottle)}
                    </BeverageFactItem> */}

                    <BeverageFactItem term="Type" icon={type}>
                      {beverage.beverageType.name}
                    </BeverageFactItem>

                    <BeverageFactItem term="Year" icon={calendar}>
                      {beverage.year}
                    </BeverageFactItem>

                    <BeverageFactItem term="Alcohol by volume" icon={calendar}>
                      {beverage.alcoholByVolume > 0 &&
                        beverage.alcoholByVolume + "%"}
                    </BeverageFactItem>

                    {/* TODO: Grapes should use an array of grapes */}
                    <BeverageFactItem term="Grapes" icon={grape}>
                      {beverage.grapes}
                    </BeverageFactItem>
                    {/* TODO: Test this when grapes are delivered as an array */}
                    {/* <BeverageFactItem term="Grapes" icon={grape}>
                      {beverage.grapes.length &&
                        beverage.grapes.map(item => (
                          <span className="comma-separated-list">{item}</span>
                        ))}
                    </BeverageFactItem> */}

                    <BeverageFactItem term="Region" icon={country}>
                      {!beverage.region && !beverage.country ? (
                        ""
                      ) : (
                        <>
                          {beverage.region && (
                            <span className="comma-separated-list">
                              {beverage.region}
                            </span>
                          )}
                          {beverage.country && (
                            <span className="comma-separated-list">
                              {beverage.country}
                            </span>
                          )}
                        </>
                      )}
                    </BeverageFactItem>

                    <BeverageFactItem term="Producer" icon={vineyard}>
                      {beverage.producer}
                    </BeverageFactItem>
                  </BeverageFactList>
                </div>
                <SlideIn isShown={quiz.isShown} toggleHandler={this.toggleQuiz}>
                  <Quiz
                    priceGuess={feedback.priceGuess}
                    changeHandler={this.handleQuizChange}
                    submitHandler={this.handleQuizSubmit}
                    isLocked={quiz.isLocked}
                    // isLocked={quiz.isLocked || feedback.isLocked ? true : false}
                    isWaiting={feedback.isLocked}
                    isProcessing={quiz.isProcessing}
                  />
                </SlideIn>
              </div>
            </FadeIn>
          )}
        </>
      </Layout>
    );
  }
}

export default Beverage;
