import React from "react";

// Styling
import styles from "./BeverageBrief.module.css";

const BeverageBrief = props => (
  <div className={styles.beverageBrief} appearance={props.appearance}>
    {/* <img
      className={styles.type}
      src={props.beverage.beverageType.typeIconUrl}
      alt={props.beverage.beverageType.name}
    /> */}

    {!props.beverage.country && !props.beverage.year ? (
      ""
    ) : (
      <p className={styles.subHeading}>
        {props.beverage.country && (
          <span className="comma-separated-list">{props.beverage.country}</span>
        )}
        {props.beverage.year > 0 && (
          <span className="comma-separated-list">{props.beverage.year}</span>
        )}
      </p>
    )}
    {!props.appearance ? (
      <h1 className={styles.heading}>{props.beverage.title}</h1>
    ) : (
      <h2 className={styles.heading}>{props.beverage.title}</h2>
    )}
  </div>
);

export default BeverageBrief;
