import React from "react";

// Components
//import Component from './react/components/Component';

// Styling
import styles from "./StepList.module.css";

const StepItem = props => (
  <div className={styles.stepList__item}>
    <div className={styles.stepList__counter}>
      <span className={styles.stepList__number}>{props.step.position}</span>
    </div>
    <p className={styles.stepList__text}>{props.step.text}</p>
  </div>
);

const StepList = props => (
  <div className={styles.stepList}>
    {props.steps.map((step, i) => (
      <StepItem step={step} key={i} />
    ))}
  </div>
);

export default StepList;
