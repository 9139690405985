import React from "react";

// Styling
import styles from "./BeverageFact.module.css";

const BeverageFactList = props => (
  <ul className={styles.list}>{props.children}</ul>
);

export default BeverageFactList;
