export function formatPrice(amount) {
  return amount.toLocaleString("da-DK", {
    style: "currency",
    currency: "DKK"
  });
}

export const addPositiveSign = number => {
  const result = number > 0 && "+";
  return result;
};
