import React from "react";

// Helpers
import { formatPrice } from "./../../../helpers/money";
import { sortClosestFirst } from "./../../../helpers/sorting";
import NumberedList from "../NumberedList/NumberedList";

// Components

const getPriceGuessOffsets = (feedbackList, beverage) => {
  // If the guess is 0, don't include it
  const validGuesses = feedbackList.filter(feedback => {
    return feedback.priceGuess;
  });

  const feedbackListWithOffstes = validGuesses.map(feedback => {
    // Set priceGuessOffset on the feedback object
    feedback.priceGuessOffset = feedback.priceGuess - beverage.pricePrBottle;
    return feedback;
  });

  return feedbackListWithOffstes;
};

const PriceGuess = props => {
  const beverage = props.beverage;
  const feedbackList = props.feedback;

  return (
    <div className="priceGuess">
      <div className="priceGuess__price">
        <span className="priceGuess__tag">Price: </span>
        <span className="priceGuess__price-result">
          {formatPrice(beverage.pricePrBottle)}
        </span>
      </div>
      <div className="priceGuess__list">
        {feedbackList && feedbackList.length ? (
          <NumberedList
            list={sortClosestFirst(
              getPriceGuessOffsets(feedbackList, beverage),
              "priceGuessOffset"
            ).slice(0, 5)}
          />
        ) : (
          "No guesses has been submitted yet."
        )}
      </div>
    </div>
  );
};

export default PriceGuess;
