import React from "react";
import PropTypes from "prop-types";

// Components
import Layout from "../components/Layout";
import Error from "../components/Error/Error";

const NotFound = props => (
  <Layout history={props.history}>
    <Error
      errorMessage={"Not found."}
      errorMessageFun="We swear, we have been looking everywhere for this!"
    />
  </Layout>
);

Layout.propTypes = {
  history: PropTypes.object
};

export default NotFound;
