import React from "react";

// Components
//import Component from './react/components/Component';

// Styling
import styles from "./Section.module.css"; // Import css modules stylesheet as styles

const SectionContainer = props => (
  <div className={styles.container}>{props.children}</div>
);

export default SectionContainer;
