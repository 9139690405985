import React from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./Avatar.module.css";

// Images
import profile from "../../../images/icons/profile--blue.svg";

const Avatar = props => (
  <div className={styles.avatar}>
    <img
      className={styles.image}
      src={props.image ? props.image : profile}
      alt="Avatar"
    />
  </div>
);

Avatar.propTypes = {
  image: PropTypes.string
};

export default Avatar;
