import React from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./BeverageFact.module.css";

const BeverageFact = props => {
  return props.children ? (
    <li className={styles.item} appearance={props.appearance}>
      <img className={styles.icon} src={props.icon} alt="icon" />
      <div className={styles.text}>
        <span className={styles.term}>{props.term}</span>
        <span className={styles.description}>{props.children}</span>
      </div>
    </li>
  ) : (
    ""
  );
};

BeverageFact.propTypes = {
  term: PropTypes.string,
  description: PropTypes.any,
  icon: PropTypes.string,
  size: PropTypes.string
};

export default BeverageFact;
