import React from "react";
import PropTypes from "prop-types";

// Components
import Section from "../Section/Section";

// Styling
import styles from "./Form.module.css"; // Import css modules stylesheet as styles

const FormSectionInput = props => (
  <Section>
    <input
      className={styles.input}
      id={props.name}
      type={props.type}
      min={props.min}
      max={props.max}
      name={props.name}
      value={props.value}
      placeholder=" "
      disabled={props.disabled}
      autoFocus={props.autoFocus}
      onChange={props.changeHandler ? props.changeHandler : undefined}
    />
    {props.labelText && (
      <label className={styles.label} htmlFor={props.name}>
        <span className={styles.labelText}>{props.labelText}</span>
        <span className={styles.labelLine} />
      </label>
    )}
  </Section>
);

FormSectionInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any, // Could be both string or number
  labelText: PropTypes.string,
  changeHandler: PropTypes.func
};

export default FormSectionInput;
