const paths = {
  login: "/",
  tastings: "/tastings",
  tastingSlug: "/:tastingSlug",
  beverages: "/beverages",
  beverageSlug: "/:beverageSlug",

  profile: "/profile",
  profileSettings: "/profile/settings",

  presentation: "/presentation",
  onboarding: "/onboarding",
  host: "/host",
  scoreBoard: "/score-board"
};

export default paths;
