import React from "react";
import { Bar } from "react-chartjs-2";

// Components
//import Component from './react/components/Component';

const formatData = feedbackList => {
  let ratings = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: []
  };

  feedbackList.filter(feedback => {
    const rating = feedback.rating;

    // Add feedback to ratings
    ratings[rating].push(feedback);
    return ratings;
  });

  // get length of each array
  for (const property in ratings) {
    ratings[property] = ratings[property].length;
  }

  // Remove people who did not vote
  delete ratings[0];

  // Make ratings an array
  let result = [];
  for (const property in ratings) {
    result.push(ratings[property]);
  }

  return result;
};

const chartData = (ratings, chartConstants) => ({
  labels: ["1 ❤︎", "2 ❤", "3 ❤︎", "4 ❤︎", "5 ❤︎", "6 ❤︎"],
  datasets: [
    {
      label: "Ratings",
      backgroundColor: chartConstants.barColor,
      borderWidth: 0,
      // hoverBackgroundColor: "rgba(255, 99, 132, 1)",
      data: ratings
    }
  ]
});

const Ratings = props => {
  const feedbackList = props.feedback;

  return (
    <div className="presentation-rating">
      {feedbackList && feedbackList.length ? (
        <div className="chart-contaier">
          <Bar
            data={chartData(formatData(feedbackList), props.chartConstants)}
            options={props.chartOptions}
            width={850}
            height={400}
            redraw={true}
          />
        </div>
      ) : (
        "No ratings has been submitted yet."
      )}
    </div>
  );
};

export default Ratings;
