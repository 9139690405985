import React, { Component } from "react";
import { Route, Link } from "react-router-dom";

// Settings
import paths from "../../settings/paths";
import { adalApiFetch } from "../../adalConfig";

// Components
import Layout from "../components/Layout";
import Heading from "../components/Heading/Heading";
import TastingsList from "../components/TastingsList/TastingsList";
import Spinner from "../components/Spinner/Spinner";
import Error from "../components/Error/Error";
import Button from "../components/Button/Button";

// Images
import barrel from "../../images/icons/types/barrel.png";

class Tastings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      tastings: []
    };
  }

  componentDidMount() {
    // TODO: Only fetch if the data has not already been fetched and saved in a state somewhere
    adalApiFetch(fetch, `${process.env.REACT_APP_API_URL}/api/event`)
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            tastings: result
          });
        },
        error => {
          // console.log("AJAX error", error);
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  render() {
    const { error, isLoaded } = this.state;

    return (
      <Layout history={this.props.history}>
        <>
          <Heading image={barrel} imageText="Barrel" heading="Tastings">
            <Route
              path="/presentation"
              render={() => (
                <Link to={this.props.match.url + paths.onboarding}>
                  <Button
                    text="Onboarding"
                    type="button"
                    priority="secondary"
                  />
                </Link>
              )}
            />
          </Heading>

          {error ? (
            <Error
              errorMessage={error.message}
              errorMessageFun="We had a little much to drink, could you come back again later?"
            />
          ) : !isLoaded ? (
            <Spinner loadingMessage="Getting events from the calendar..." />
          ) : (
            <TastingsList
              tastings={this.state.tastings}
              match={this.props.match}
            />
          )}
        </>
      </Layout>
    );
  }
}

export default Tastings;
