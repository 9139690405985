import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";

// Settings
import paths from "../../../settings/paths";
import { adalApiFetch } from "../../../adalConfig";

// Helpers
// import { getAverage, getAverageWithDecimal } from "./../../../helpers/math";

// Components
import Layout from "../../components/Layout";
import Spinner from "../../components/Spinner/Spinner";
import Error from "../../components/Error/Error";
import Dashboard from "../../components/Dashboard/Dashboard";
import Divider from "../../components/Divider/Divider";
import Presentation from "../../components/Presentation/Presentation";
import Rating from "../../components/Presentation/Rating";
import PriceGuess from "../../components/Presentation/PriceGuess";
import Marquee from "../../components/Marquee/Marquee";
import RankingList from "../../components/RankingList/RankingList";

// Images
import Close from "../../../images/icons/close--blue.svg";

const chartConstants = {
  fontFamily: "'semplicitapro', Arial, sans-serif",
  fontSize: 30,
  fontStyle: 600,
  fontColor: "#414255",

  strokeColor: "#414255",
  strokeWidth: 2,
  barColor: "#387c6a",
  barHoverColor: "rgba(255, 99, 132, 1)"
};

class Beverage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      beverage: {},
      feedback: {},
      feedbackIsLoaded: false,
      tasting: {},
      showAccumulatedScore: true,
      chartOptions: {
        // responsive: true,

        scales: {
          xAxes: [
            {
              display: true,
              categoryPercentage: 0.7,
              barPercentage: 0.7,
              // barThickness: 100,
              // maxBarThickness: 100,

              scaleLabel: {
                display: false,
                labelString: "Hearts",
                fontFamily: chartConstants.fontFamily,
                fontSize: chartConstants.fontSize,
                fontStyle: chartConstants.fontStyle,
                fontColor: chartConstants.fontColor
              },

              ticks: {
                fontSize: chartConstants.fontSize,
                fontStyle: 400,
                fontColor: chartConstants.fontColor
              },

              gridLines: {
                display: false,
                lineWidth: chartConstants.strokeWidth,
                color: chartConstants.strokeColor,
                tickMarkLength: 20
              }
            }
          ],

          yAxes: [
            {
              display: true,

              scaleLabel: {
                // http://www.chartjs.org/docs/latest/axes/labelling.html
                display: false,
                labelString: "People",
                fontFamily: chartConstants.fontFamily,
                fontSize: chartConstants.fontSize,
                fontStyle: chartConstants.fontStyle,
                fontColor: chartConstants.fontColor
              },

              ticks: {
                // http://www.chartjs.org/docs/latest/axes/cartesian/#tick-configuration
                // http://www.chartjs.org/docs/latest/axes/styling.html

                // fontFamily: chartConstants.fontFamily,
                fontSize: chartConstants.fontSize,
                fontStyle: 400,
                fontColor: chartConstants.fontColor,

                min: 0
                // max: 6
              },
              gridLines: {
                // http://www.chartjs.org/docs/latest/axes/styling.html

                display: false,
                color: chartConstants.strokeColor,
                lineWidth: chartConstants.strokeWidth,
                // drawBorder: true,
                // drawOnChartArea: true,
                // offsetGridLines: true,

                // Ticks
                // drawTicks: true,
                tickMarkLength: 20

                // Zero line
                // zeroLineWidth: 5,
                // zeroLineColor: "rgba(0,0,0,.1)"
              }
            }
          ]
        },

        tooltips: {
          enabled: false
        },

        legend: {
          display: false
          // position: "bottom",
          // labels: {
          //   fontFamily: chartConstants.fontFamily,
          //   fontSize: chartConstants.fontSize,
          //   fontStyle: chartConstants.fontStyle,
          //   fontColor: chartConstants.fontColor
          // }
        }
      }
    };
  }

  getRatingsArray = () => {
    let result = [];

    this.state.feedback &&
      this.state.feedback.map(feedback => {
        if (feedback.rating > 0) {
          result.push(feedback.rating);
        }

        return false;
      });

    return result;
  };

  getPriceGuessArray = () => {
    // TODO: This calculation should probably be different? Should it only show the difference from the price?
    let result = [];

    this.state.feedback &&
      this.state.feedback.map(feedback => {
        if (feedback.priceGuess > 0) {
          result.push(feedback.priceGuess);
        }

        return false;
      });

    return result;
  };

  getIndexOfTasting = (list, id) => list.findIndex(item => item.id === id) + 1;

  getTasting = () => {
    return adalApiFetch(
      fetch,
      `${process.env.REACT_APP_API_URL}/api/event/slug/${
        this.props.match.params.tastingSlug
      }`
    ).then(res => res.json());
  };

  getBeverage = () => {
    return adalApiFetch(
      fetch,
      `${process.env.REACT_APP_API_URL}/api/beverage/slug/${
        this.props.match.params.beverageSlug
      }`
    ).then(res => res.json());
  };

  // Feedback
  getFeedback = () => {
    adalApiFetch(
      fetch,
      `${process.env.REACT_APP_API_URL}/api/Beverage/${
        this.props.match.params.beverageSlug
      }/feedbacksummary`
    )
      // .then(response => {
      //   if (!response.ok) {
      //     console.warn(
      //       "Fetch error:",
      //       response.status,
      //       response.statusText,
      //       response
      //     );
      //     this.setState({
      //       isLoaded: true,
      //       error: { message: response.statusText }
      //     });
      //     throw Error(response.statusText);
      //   }
      //   return response;
      // })
      .then(res => res.json())
      .then(
        result => {
          // console.log("getFeedback was successful", result);
          this.setState({
            feedback: result.feedback,
            feedbackIsLoaded: true
          });
        }

        // error => {
        //   console.log("getFeedback was UNsuccessful", error);
        //   !this.state.error &&
        //     this.setState({
        //       // isLoaded: true,
        //       error
        //     });
        // }
      );
  };

  componentDidMount() {
    this.getFeedback();

    this.getBeverage().then(
      result => {
        // console.log("getBeverage was successful", result);
        this.setState({
          beverage: result,
          isLoaded: true
        });
      },
      error => {
        // console.log("getBeverage was UNsuccessful", error);
        this.setState({
          isLoaded: true,
          error
        });
      }
    );

    this.getTasting().then(result => {
      // console.log("getTasting was successful", result);
      this.setState({
        tasting: result
      });
    });
  }

  render() {
    const beverage = this.state.beverage;
    const feedback = this.state.feedback;
    const feedbackIsLoaded = this.state.feedbackIsLoaded;
    const tasting = this.state.tasting;
    const chartOptions = this.state.chartOptions;
    const { error, isLoaded } = this.state;

    return (
      <>
        {error ? (
          <Error
            errorMessage={error.message}
            errorMessageFun="Oops! It seems we drank the last bottle. Sorry, not sorry"
          />
        ) : !isLoaded ? (
          <Spinner loadingMessage="Opening your bottle, one sec!" />
        ) : (
          <>
            <Layout navigation={false} fullHeight="true">
              <>
                <Link
                  className="close"
                  to={
                    paths.presentation +
                    paths.tastings +
                    "/" +
                    this.props.match.params.tastingSlug
                  }
                >
                  <img className="close__icon" src={Close} alt="Close host" />
                </Link>
                <div className="presentation-beverage">
                  <div className="presentation-beverage__dashboard">
                    <Dashboard
                      url={this.props.match.url}
                      pathname={this.props.location.pathname}
                      // indexOfTasting={2}
                      indexOfTasting={
                        tasting.beverages
                          ? this.getIndexOfTasting(
                              tasting.beverages,
                              beverage.id
                            )
                          : 0
                      }
                      numberOfBeverages={
                        tasting.beverages ? tasting.beverages.length : 0
                      }
                      // averageRating={getAverageWithDecimal(
                      //   this.getRatingsArray()
                      // )}
                      numberOfRatings={
                        feedbackIsLoaded ? this.getRatingsArray().length : 0
                      }
                      // averagePriceGuess={getAverage(this.getPriceGuessArray())}
                      numberOfGuesses={
                        feedbackIsLoaded ? this.getPriceGuessArray().length : 0
                      }
                    />
                  </div>
                  <div className="presentation-beverage__wrapper">
                    <h1 className="presentation-beverage__heading">
                      {beverage.title}
                    </h1>
                    <Divider
                      image={beverage.beverageType.typeIconUrl}
                      alt={beverage.beverageType.name}
                      appearance="thin"
                    />
                    <div className="presentation-beverage__content">
                      <Switch>
                        <Route
                          exact
                          path="/presentation/tastings/:tastingSlug/beverages/:beverageSlug"
                          component={() => <Presentation beverage={beverage} />}
                        />
                        <Route
                          exact
                          path="/presentation/tastings/:tastingSlug/beverages/:beverageSlug/presentation"
                          component={() => <Presentation beverage={beverage} />}
                        />
                        <Route
                          exact
                          path="/presentation/tastings/:tastingSlug/beverages/:beverageSlug/rating"
                          component={() => (
                            <Rating
                              chartOptions={chartOptions}
                              chartConstants={chartConstants}
                              feedback={feedback}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/presentation/tastings/:tastingSlug/beverages/:beverageSlug/price-guess"
                          component={() => (
                            <PriceGuess
                              beverage={beverage}
                              feedback={feedback}
                            />
                          )}
                        />
                      </Switch>
                    </div>
                  </div>
                  <div className="presentation-beverage__marquee">
                    <Marquee active={this.state.showAccumulatedScore}>
                      {/* TODO: Use real data in the marquee */}
                      <RankingList
                        data={[
                          {
                            rank: 1,
                            name: "Chris Christensen",
                            difference: 15
                          },
                          {
                            rank: 2,
                            name: "Lars Tønnes",
                            difference: -22
                          },
                          {
                            rank: 3,
                            name: "Mia Frederiksen",
                            difference: 39
                          },
                          {
                            rank: 4,
                            name: "Louise Kampmann",
                            difference: -89
                          },
                          {
                            rank: 5,
                            name: "Michael Wittrup",
                            difference: -122
                          },
                          {
                            rank: 6,
                            name: "Suzan Orry",
                            difference: 159
                          },
                          {
                            rank: 7,
                            name: "Denise Uguis",
                            difference: 179
                          },
                          {
                            rank: 8,
                            name: "Marielle Ribbing",
                            difference: 314
                          }
                        ]}
                      />
                    </Marquee>
                  </div>
                </div>
              </>
            </Layout>
          </>
        )}
      </>
    );
  }
}

export default Beverage;
