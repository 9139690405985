import React from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./Button.module.css";

const Button = props => (
  <button
    className={styles.button}
    type={props.type}
    disabled={
      (props.inactive || props.processing || props.loading) && "disabled"
    }
    onClick={props.onClick}
    appearance={props.appearance}
    priority={props.priority}
  >
    {props.loading
      ? props.loading
      : props.inactive
      ? props.inactive
      : props.processing
      ? props.processing
      : props.text}
  </button>
);

export const ButtonPlaceholder = props => (
  <div className={styles.buttonPlaceholder}>{props.children}</div>
);

Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  processing: PropTypes.string,
  inactive: PropTypes.string,
  appearance: PropTypes.string,
  priority: PropTypes.string,
  onClick: PropTypes.func
};

export default Button;
