import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// Settings
import paths from "../../../settings/paths";

// Helpers
import { goUp } from "../../../helpers/url";

// Styling
import styles from "./Header.module.css";

// Images
import profile from "../../../images/icons/profile--blue.svg";
import arrowLeft from "../../../images/icons/arrow__left--blue.svg";
import settings from "../../../images/icons/edit--blue.svg";

const Header = props => (
  <header className={styles.header}>
    <div className={styles.left}>
      {/* Do not show back button if on the tastings page for client or presentation */}
      {props.history.location.pathname !== paths.tastings &&
        props.history.location.pathname !== paths.presentation && (
          // Back button
          <span
            className={styles.link}
            // Presentation mode: go up, else go back
            onClick={
              props.history.location.pathname.includes(paths.presentation)
                ? () => goUp(props.history)
                : props.history.goBack
            }
          >
            <img
              className={styles.icon + " " + styles.iconArrow}
              src={arrowLeft}
              alt="Back"
            />
          </span>
        )}
    </div>
    <div className={styles.middle} />
    <div className={styles.right}>
      {/* Not settings page */}
      {props.history.location.pathname !== paths.profileSettings &&
        (props.history.location.pathname === paths.profile ? (
          // Profile page
          <Link className={styles.link} to={paths.profileSettings}>
            <img className={styles.icon} src={settings} alt="Settings" />
          </Link>
        ) : (
          // Else
          <Link className={styles.link} to={paths.profile}>
            <img className={styles.icon} src={profile} alt="Profile" />
          </Link>
        ))}
    </div>
  </header>
);

Header.propTypes = {
  history: PropTypes.object
};

export default Header;
