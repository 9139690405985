export const goUp = history => {
  let pathname = history.location.pathname;
  let result = truncateUrl(pathname);

  // Check if it needs to truncate further
  if (
    result.substr(result.lastIndexOf("/")) === "/tastings" ||
    result.substr(result.lastIndexOf("/")) === "/beverages"
  ) {
    result = truncateUrl(result);
  }

  // Else push it
  history.push(result);
};

const truncateUrl = pathname => {
  return pathname.substr(0, pathname.lastIndexOf("/"));
};
