import React from "react";

// Components
import TastingsItem from "./TastingsItem";
import Divider from "../Divider/Divider";
import FadeIn from "../FadeIn/FadeIn";

// Helpers
import { sortNewestFirst } from "../../../helpers/sorting";

// Styling
import styles from "./TastingsList.module.css";

const TastingsList = props => {
  let tastingsByYear = {};

  props.tastings.filter(tasting => {
    const tastingYear = new Date(tasting.eventDate).getFullYear();

    // If the array does not exist, create it
    !tastingsByYear[tastingYear] && (tastingsByYear[tastingYear] = []);

    // Add tasting to tastingsByYear
    tastingsByYear[tastingYear].push(tasting);
    return tastingsByYear;
  });

  return (
    <FadeIn>
      <div className={styles.tastingsList}>
        {Object.keys(tastingsByYear)
          .reverse()
          .map((year, i) => {
            return (
              <React.Fragment key={i}>
                <Divider text={year} unobtrusive="true" />
                <ul className={styles.yearList}>
                  {/* Sort lists by date, then map them */}
                  {sortNewestFirst(tastingsByYear[year], "eventDate").map(
                    (tasting, i) => (
                      <TastingsItem key={i} {...tasting} match={props.match} />
                    )
                  )}
                </ul>
              </React.Fragment>
            );
          })}
      </div>
    </FadeIn>
  );
};

export default TastingsList;
