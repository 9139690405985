import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";

// Components
import SectionContainer from "../Section/SectionContainer";
import Section from "../Section/Section";
import SpinnerAnimation from "./SpinnerAnimation";

// Styling
import styles from "./Spinner.module.css";

const Spinner = props => (
  <TransitionGroup>
    <CSSTransition
      component="div"
      classNames={{
        appear: styles.appear,
        appearActive: styles.appearActive,
        enter: styles.enter,
        enterActive: styles.enterActive,
        enterDone: styles.enterDone,
        exit: styles.exit,
        exitActive: styles.exitActive,
        exitDone: styles.exitDone
      }}
      appear={true}
      timeout={250}
      enter={false}
      exit={false}
    >
      <SectionContainer>
        <Section>
          <div className={styles.text}>
            {props.loadingMessage ? props.loadingMessage : "Loading..."}
          </div>
          <SpinnerAnimation />
        </Section>
      </SectionContainer>
    </CSSTransition>
  </TransitionGroup>
);

Spinner.propTypes = {
  loadingMessage: PropTypes.string
};

export default Spinner;
