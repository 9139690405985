import React from "react";

// Components
import FadeIn from "../components/FadeIn/FadeIn";
import LandingPage from "../components/Landing/Landing";

const Landing = props => (
  <FadeIn>
    <LandingPage history={props.history} />
  </FadeIn>
);

export default Landing;
