import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

// Styling
import styles from "./FadeIn.module.css";

const FadeIn = props => (
  <TransitionGroup className="transition-wrapper">
    <CSSTransition
      classNames={{
        appear: styles.fadeInAppear,
        appearActive: styles.fadeInAppearActive,
        enter: styles.fadeInEnter,
        enterActive: styles.fadeInEnterActive,
        enterDone: styles.fadeInEnterDone,
        exit: styles.fadeInExit,
        exitActive: styles.fadeInExitActive,
        exitDone: styles.fadeInExitDone
      }}
      appear={true}
      timeout={250}
      enter={false}
      exit={false}
    >
      <div className="transition-element">{props.children}</div>
    </CSSTransition>
  </TransitionGroup>
);

export default FadeIn;
