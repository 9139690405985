import React from "react";
import PropTypes from "prop-types";

// Components
import Ranking from "./RankingItem";

// Styling
import styles from "./Ranking.module.css";

const RankingList = props => {
  return (
    <ul className={styles.list}>
      {props.data.map((person, i) => (
        <Ranking key={i} {...person} />
      ))}
    </ul>
  );
};

RankingList.propTypes = {
  data: PropTypes.array
};

export default RankingList;
