import React from "react";
import PropTypes from "prop-types";

// Helpers
import { formatPrice, addPositiveSign } from "../../../helpers/money";

// Styling
import styles from "./Ranking.module.css";

const Ranking = props => (
  <li className={styles.item}>
    <span className={styles.position}>{props.rank}</span>
    <span className={styles.name}>{props.name}</span>
    <span className={styles.difference}>
      {addPositiveSign(props.difference)}
      {formatPrice(props.difference)}
    </span>
  </li>
);

Ranking.propTypes = {
  data: PropTypes.array
};

export default Ranking;
