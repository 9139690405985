import React from "react";
import PropTypes from "prop-types";

// Components
import Header from "./Header/Header";
import FadeIn from "./FadeIn/FadeIn";

const Layout = props => (
  <div className="wrapper" fullheight={props.fullHeight}>
    {props.navigation !== false && <Header history={props.history} />}

    <FadeIn>{props.children}</FadeIn>
  </div>
);

Layout.propTypes = {
  fullHeight: PropTypes.string,
  navigation: PropTypes.bool,
  history: PropTypes.object
};

export default Layout;
